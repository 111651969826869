/* eslint-disable no-undef */
import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IUserIntercom } from 'types/intercomUser.type';

import {
  IUserDataSocialLogin,
  AuthContext,
  ISocialMediaSignUp,
  IAuthReturnType,
  IAuthContext,
} from './AuthContext';
import { isLoggedInState, sessionState } from './state';
import {
  LocalStorage,
  DeviceId,
  AccessOriginId,
  ApiResultsKind,
  AppLocationURLs,
} from '@consts/index';
import { useMixpanel, MIXPANEL_EVENTS } from '@contexts/index';
import { userTimezone } from '@helpers/index';
import { useUserSettings } from '@hooks/index';
import api, { handleLogout } from '@services/api';
import { fetchUserInfo } from '@services/userMethods';

const isAllowed = (permission: number): boolean => {
  return (permission && permission === 1) || permission === 2;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const { getUserSettings } = useUserSettings();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const [session, setSession] = useRecoilState(sessionState);

  const { identify, reset, setOnceProps, track } = useMixpanel();
  const signed = isLoggedIn;
  const userInfo = session;
  const InitialFirstAccessObject = {
    first_access_service_options: true,
    first_access_services: true,
    first_access_service_questions: true,
    first_access_faqs: true,
  };
  const userIntercom: IUserIntercom = {
    user_id: userInfo?.user?.id.toString() || '',
    name: userInfo?.user?.first_name || '',
    email: userInfo?.user?.email || '',
    accountName: userInfo?.account?.name || '',
    accountId: userInfo?.account?.id || '',
    hasIntegration: userInfo?.account?.hasIntegration ? 'true' : 'false' || '',
  };

  const logout = (): void => {
    reset();
    handleLogout();
    window.location.assign(AppLocationURLs.HOME);
  };

  const setUserPhoneValidated = () => {
    setSession({
      ...session,
      user: {
        ...session.user,
        phone_validated_at: new Date().toISOString(),
        phone_validation_required: false,
      },
    });
  };

  const setAuthTokens = (data) => {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, data.access_token);
    localStorage.setItem(
      LocalStorage.BOOKING_BUSINESS_FIRST_ACCESS,
      JSON.stringify(InitialFirstAccessObject)
    );
  };

  const newTeamLogin = async (data): Promise<void> => {
    setAuthTokens(data);
    const { kind, settings } = await getUserSettings();
    const { kind: kindInfo, user } = await fetchUserInfo();

    if (kind === ApiResultsKind.OK && kindInfo === ApiResultsKind.OK) {
      track(MIXPANEL_EVENTS.LOGIN);
      const newData = {
        ...data,
        chat_profile: {
          ...user.chat_profile,
        },
        settings: {
          ...settings,
        },
      };
      setSession(newData);
    }
  };

  const setUserCredentials = (data): boolean => {
    if (!isAllowed(data.permission_id)) {
      setError(false);
      return false;
    }

    setSession(data);
    setError(false);

    setAuthTokens(data);

    return true;
  };

  const doAuthFlow = (data: any): boolean => {
    try {
      setAuthTokens(data);
      track(MIXPANEL_EVENTS.LOGIN);
      localStorage.setItem(LocalStorage.ACCOUNT_ID, data.account.id);
      return setUserCredentials(data);
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const login = async (
    email: string,
    password: string
  ): Promise<IAuthReturnType> => {
    try {
      const timezone = userTimezone();
      const { data } = await api.post('session', {
        email,
        password,
        timezone,
        origin: {
          device_id: DeviceId.COMPUTER,
          access_origin_id: AccessOriginId.WEB_APP,
        },
      });

      localStorage.setItem(LocalStorage.ACCESS_TOKEN, data.access_token);

      const { kind } = await getUserSettings();

      if (kind === ApiResultsKind.OK) {
        if (setUserCredentials(data)) {
          identify(data.user.id);
          track(MIXPANEL_EVENTS.LOGIN);
          const { created_at, email, full_name, first_name, last_name } =
            data.user;
          const props = {
            email: email,
            name: full_name,
            created: created_at,
            'First Name': first_name,
            'Last Name': last_name,
          };
          setOnceProps(props);

          history.replace(AppLocationURLs.HOME);

          return { success: true, message: '', data: '' };
        }

        handleLogout();

        return { success: false, message: 'notAdmin', data: data };
      }
    } catch (err) {
      setError(true);
      return { success: false, message: 'errorRequest', data: '' };
    }
  };

  const createAccount = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    recaptcha: string
  ): Promise<IAuthReturnType> => {
    try {
      const { data } = await api.post('user', {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        recaptcha,
      });

      localStorage.setItem(LocalStorage.ACCESS_TOKEN, data.access_token);
      localStorage.setItem(
        LocalStorage.BOOKING_BUSINESS_FIRST_ACCESS,
        JSON.stringify(InitialFirstAccessObject)
      );

      identify(data.user.id);
      const { created_at, full_name } = data.user;
      const props = {
        Email: email,
        email: email,
        name: full_name,
        created: created_at,
        'User ID': data.user.id,
        'First Name': firstName,
        'Last Name': lastName,
      };

      setOnceProps(props);
      return { success: true, message: '', data };
    } catch (e: any) {
      const { errors } = e.response.data;

      if (errors?.recaptcha) {
        return { success: false, message: 'recaptchaFail', data: undefined };
      }

      if (errors?.email[0] === 'The email has already been taken.') {
        return { success: false, message: 'emailTaken', data: undefined };
      }

      return { success: false, message: '', data: undefined };
    }
  };

  const socialMediaSignUp = async (
    userSocialData: IUserDataSocialLogin
  ): Promise<ISocialMediaSignUp> => {
    try {
      const { data } = await api.post(
        'socialMediaSession',
        {
          first_name: userSocialData.firstName,
          last_name: userSocialData.lastName,
          email: userSocialData.email,
          social_media_id: userSocialData.socialMediaId,
          social_media_provider: userSocialData.socialMediaProvider,
          id_token: userSocialData.id_token,
          existing_user_only: true,
        },
        { headers: { DontLogout: 'true' } }
      );
      return {
        success: doAuthFlow(data),
        message: '',
        errorMessage: '',
      };
    } catch (e: any) {
      switch (e.response.data.message) {
        case 'account not found':
          return {
            success: false,
            message: '',
            errorMessage: 'accountNotFund',
          };
        case 'Invalid credentials':
          return {
            success: false,
            message: '',
            errorMessage: 'invalidCredentials',
          };

        default:
          return {
            success: false,
            message: '',
            errorMessage: 'adminOnly',
          };
      }
    }
  };

  const hooks: IAuthContext = {
    userInfo,
    userIntercom,
    signed,
    error,
    login,
    logout,
    socialMediaSignUp,
    setUserPhoneValidated,
    createAccount,
    newTeamLogin,
  };

  return <AuthContext.Provider value={hooks}>{children}</AuthContext.Provider>;
};

const useAuth = (): IAuthContext => useContext(AuthContext);

export { AuthProvider, useAuth };
