import React, { useEffect, useState } from 'react';

import { DateTime, DateTimeUnit } from 'luxon';
import { Trans } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { useOfferDrawer } from './useOfferDrawer';
import {
  filterEvents,
  getFormattedEvent,
  getFormattedEvents,
  getFormattedResources,
  getOfferById,
  handleUsersList,
  isFirstDaySunday,
  verifyResources,
} from '../helpers';
import { TkAlert } from '@components/index';
import {
  ICalendarViewEnum,
  NotificationTypesEnum,
  WeekViewTypesEnum,
} from '@consts/index';
import {
  useAuth,
  useNotifications,
  useSearch,
  useSnackbar,
} from '@contexts/index';
import { useAccountProjectOffers } from '@features/projectOffers/hooks';
import { encodedUserTimezone, getTimezoneDateFromISO } from '@helpers/index';
import { useDrawer, useUserSettings } from '@hooks/index';
import { NotificationMeta } from '@hooks/notification/types';
import { EventQueryParamsEnum } from '@pages/Home/hooks/types';
import {
  assigneesData,
  calendarGridViewSelector,
  calendarWeekViewSelector,
  checklistsData,
  countEnqueuedSnackbar,
  crewsData,
  customersData,
  event,
  eventEndDate,
  eventStartDate,
  getUserAccount,
  getUserAccountId,
  isAnyDrawerOpenData,
  isFilterSet,
  locationsData,
  offersData,
  resetEditEvent,
  resetEvent,
  selectedDateCalendarData,
  selectedEventId,
  selectedOffer,
  selectedOfferId,
  setDrawerData,
  setEventEdit,
  tasksFilter,
} from '@recoilData/index';
import api from '@services/api';
import {
  loadCrewsApiData,
  loadCustomerApiData,
  loadAssigneeApiData,
  loadLocationsApiData,
  loadChecklistsApiData,
} from '@services/apiLoaders';
import { fetchProjectById } from '@services/projectMethods';

export const useHomePage = () => {
  //Snackbar Functions
  const { fireTError } = useSnackbar();

  const location = useLocation();
  const history = useHistory();

  //React Query Functions
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = useState(false);
  const [retrigger, setRetrigger] = useState(false);
  const [searchMoreEventsInFuture, setSearchMoreEventsInFuture] = useState<
    'unset' | 'allFetched' | 'fetchFuture'
  >('unset');

  //Intercom Functions
  const { boot, showMessages, show, update } = useIntercom();

  //Snack Props
  const { addAlert, handleClose } = useSnackbar();

  //Account Props
  const { userInfo, userIntercom, signed } = useAuth();
  const account = useRecoilValue(getUserAccount);
  const accountId = useRecoilValue(getUserAccountId);
  const { userSettings } = useUserSettings();

  //Search Props
  const { term, setTerm } = useSearch();
  const [oldTerm, setOldTerm] = useState('');

  //Notification Props
  const { selectedNotification, resetSelectedNotification } =
    useNotifications();

  //Date Functions
  const setEndDate = useSetRecoilState(eventEndDate);
  const setStartDate = useSetRecoilState(eventStartDate);
  const weekViewType = useRecoilValue(calendarWeekViewSelector);
  const [calendarView, setCalendarView] = useRecoilState(
    calendarGridViewSelector
  );
  const [selectedDateCalendar, setSelectedDateCalendar] = useRecoilState(
    selectedDateCalendarData
  );

  //Calendar Props
  const isWeekView = calendarView === ICalendarViewEnum.WEEK_VIEW;
  const isMonthView = calendarView === ICalendarViewEnum.MONTH_VIEW;
  const isWeekViewTypeAssignee = weekViewType === WeekViewTypesEnum.ASSIGNEE;
  const isWeekViewTypeCustomer = weekViewType === WeekViewTypesEnum.CUSTOMER;
  const dateModifierFlag: DateTimeUnit = isMonthView ? 'month' : 'week';

  //Filter Functions
  const setFilter = useSetRecoilState(isFilterSet);
  const setTasksFilter = useSetRecoilState(tasksFilter);

  //Event Related Functions
  const setEvent = useSetRecoilState(event);
  const setEditEvent = useSetRecoilState(setEventEdit);
  const resetMainEvent = useResetRecoilState(resetEvent);
  const [eventId, setEventId] = useRecoilState(selectedEventId);
  const resetSelectedEvent = useResetRecoilState(resetEditEvent);
  const { projectOffers } = useAccountProjectOffers();
  const projectOffersCount = projectOffers
    ? Object.entries(projectOffers)
        ?.map((project) => project[1].map((p) => p.id))
        .flat().length
    : 0;

  //Offer Related Props
  const setSelectedOffer = useSetRecoilState(selectedOffer);
  const [offers, setOffersData] = useRecoilState(offersData);
  const [offerId, setOfferId] = useRecoilState(selectedOfferId);
  const [showRequestsButton, setShowRequestsButton] = useState<
    'hidden' | 'visible'
  >('hidden');

  //Drawer Related State
  const {
    callDrawer,
    closeDrawers,
    currentDrawer: { main },
    types: { MainDrawersTypes },
  } = useDrawer();
  const { openProjectOfferCalendarEvent, closeProjectOfferList } =
    useOfferDrawer();
  const [drawers, setDrawerOpen] = useRecoilState(setDrawerData);
  const anyRecoilDrawerOpen = useRecoilValue(isAnyDrawerOpenData);
  const hasEnqueuedSnackbar = useRecoilValue(countEnqueuedSnackbar);
  const isAnyDrawerOpen = !!main.id || anyRecoilDrawerOpen;

  //Recoil Data
  const setCrews = useSetRecoilState(crewsData);
  const setCustomers = useSetRecoilState(customersData);
  const setAssignees = useSetRecoilState(assigneesData);
  const setLocations = useSetRecoilState(locationsData);
  const setChecklists = useSetRecoilState(checklistsData);

  //Notification useEffect
  useEffect(() => {
    const triggerNotification = (notificationMeta: NotificationMeta) => {
      if (!notificationMeta) return;

      switch (notificationMeta.type) {
        case NotificationTypesEnum.SUPPORT_TEAM_MESSAGE:
          showMessages();
          break;

        case NotificationTypesEnum.USER_SUPPORT_NOTIFICATION:
          show();
          break;

        case NotificationTypesEnum.UPCOMING_TASKS:
          if (notificationMeta.startTimeEpoch) {
            setSelectedDateCalendar(
              DateTime.fromSeconds(notificationMeta.startTimeEpoch).toJSDate()
            );
            setRetrigger((prev) => !prev);
          }
          break;

        case NotificationTypesEnum.NEW_TASKS_IMPORTED:
          if (notificationMeta.tasks) {
            setFilter(true);
            setTasksFilter(notificationMeta.tasks);
          }
          break;

        case NotificationTypesEnum.NEW_BOOKING_PROJECT_REQUEST:
          if (notificationMeta.offerId)
            setDrawerEvent(notificationMeta.offerId, true);
          break;

        default:
          if (notificationMeta.taskId) setDrawerEvent(notificationMeta.taskId);
          break;
      }

      resetSelectedNotification();
    };

    setTimeout(() => triggerNotification(selectedNotification), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification]);

  //Intercom update to the left when a drawer is open.
  useEffect(() => {
    update({
      alignment: isAnyDrawerOpen || !!hasEnqueuedSnackbar ? 'left' : 'right',
    });
  }, [isAnyDrawerOpen, hasEnqueuedSnackbar, update]);

  //Intercom useEffect
  useEffect(() => {
    boot(userIntercom);
  }, [boot, userInfo, userIntercom, account]);

  //Request Button useEffect
  useEffect(() => {
    projectOffersCount > 0
      ? setShowRequestsButton('visible')
      : setShowRequestsButton('hidden');
  }, [projectOffersCount]);

  useEffect(() => {
    if (signed && userInfo.user.id !== 0) {
      setEnabled(true);
    }
  }, [userInfo, signed]);

  //Event Fetching React Query
  const { isFetching: isLoadingEvt, refetch: refetchEvent } = useQuery(
    ['getEvent', eventId],
    async () => {
      resetMainEvent();
      resetSelectedEvent();
      if (!eventId || eventId === 0) {
        closeDrawers();
        history.replace({
          pathname: location.pathname,
          search: '',
        });
        return;
      }

      const params = new URLSearchParams({
        [EventQueryParamsEnum.EVENT_QUERY_PARAM]: eventId.toString(),
      });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });

      callDrawer({ main: { id: MainDrawersTypes.EDIT_TASK } });
      const { project } = await fetchProjectById({
        projectId: eventId,
      });

      if (!project) {
        closeDrawers();
        fireTError('error.taskNotFound', { ns: 'events' });
        return;
      }

      const evt = getFormattedEvent(project);
      setEvent(evt);
      setEditEvent(evt);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ['customersDataQuery', enabled],
    async () => {
      if (accountId) await loadCustomerApiData(accountId, setCustomers);
    },
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ['locationsDataQuery', enabled],
    async () => {
      if (accountId) await loadLocationsApiData(accountId, setLocations);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  useQuery(
    ['checklistsDataQuery', enabled],
    async () => {
      if (accountId) await loadChecklistsApiData(accountId, setChecklists);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  useQuery(
    ['assigneesDataQuery', enabled],
    async () => {
      if (accountId) await loadAssigneeApiData(accountId, setAssignees);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  useQuery(
    ['crewsDataQuery', enabled],
    async () => {
      if (accountId) await loadCrewsApiData(accountId, setCrews);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  const updateEvent = useMutation(
    (dropData: any) => {
      return api
        .put(`project/${dropData.id}/update`, {
          start_time_epoch: dropData.selectedStart,
          end_time_epoch: dropData.selectedEnd,
          notify: dropData.notify,
        })
        .then(() => {
          setEventId(dropData.id);
          if (eventId === dropData.id) refetchEvent();
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accountEvents');
      },
    }
  );

  const updateResourceEvent = useMutation(
    (dropData: any) => {
      const users = dropData.extendedProps.assigneeIds;
      const oldResource = dropData.oldResource;
      const newResource = dropData.newResource;

      const newEvent = {
        start_time_epoch: dropData.selectedStart,
        end_time_epoch: dropData.selectedEnd,
        user_id: users.filter((id) => id !== 0),
        notify: dropData.notify,
      };

      if (isWeekViewTypeCustomer && verifyResources(newResource, oldResource)) {
        newEvent['customer_id'] =
          newResource === '0' ? null : Number(newResource);
        newEvent['location_id'] = null;
      }

      if (isWeekViewTypeAssignee && verifyResources(newResource, oldResource)) {
        newEvent['user_id'] = handleUsersList(users, oldResource, newResource);
      }

      return api.put(`project/${dropData.id}`, newEvent).then(() => {
        setEventId(dropData.id);
        if (eventId === dropData.id) refetchEvent();
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accountEvents');
      },
    }
  );

  const calendarFetchDefault = {
    events: [],
    resources: [],
  };

  //Calendar Events Fetch
  const {
    isFetching,
    data: results,
    refetch,
  } = useQuery(
    ['accountEvents', term, retrigger, weekViewType, calendarView, accountId],
    async () => {
      const results = {
        events: [],
        resources: [],
      };

      const YEARS_IN_FUTURE = 5;

      const initialDate = DateTime.fromJSDate(selectedDateCalendar)
        .startOf(dateModifierFlag)
        .minus({ week: 1 });

      const finalDate = DateTime.fromJSDate(selectedDateCalendar)
        .endOf(dateModifierFlag)
        .minus({
          day: isFirstDaySunday(userSettings.first_day, isMonthView),
        })
        .toSeconds();

      const finalDatePlusYears = DateTime.fromJSDate(selectedDateCalendar)
        .plus({ years: YEARS_IN_FUTURE })
        .endOf(dateModifierFlag)
        .toSeconds();

      const requestParams = {
        start_time_from: initialDate.toSeconds(),
        start_time_until:
          searchMoreEventsInFuture === 'fetchFuture'
            ? finalDatePlusYears
            : finalDate,
        with_assignments: 1,
        with_location: 1,
        with_customer: 1,
        size: searchMoreEventsInFuture === 'fetchFuture' ? 1 : 1000,
        sort_by: 'start_time_epoch',
      };

      if (term.length > 0) requestParams['search_term'] = term;
      if (isWeekView) requestParams['with_resource'] = weekViewType;

      try {
        const { data } = await api.get('project', {
          params: requestParams,
        });

        const { data: dataOffers } = await api.get(
          `account/${accountId}/projectOffer?timezone=${encodedUserTimezone()}`,
          {
            params: {
              start_time_from: initialDate.toSeconds(),
              start_time_until:
                searchMoreEventsInFuture === 'fetchFuture'
                  ? finalDatePlusYears
                  : finalDate,
            },
          }
        );

        setOffersData(dataOffers);

        const { filteredEvents, filteredIds } = filterEvents(
          data.data,
          initialDate
            .plus({ week: 1 })
            .minus({ day: isFirstDaySunday(userSettings.first_day) })
            .toSeconds(),
          searchMoreEventsInFuture === 'fetchFuture'
            ? finalDatePlusYears
            : finalDate,
          weekViewType
        );

        if (term.length > 0) {
          results.events = handleTermSearch(filteredEvents, dataOffers, term);
        } else {
          results.events = getFormattedEvents(
            filteredEvents,
            dataOffers,
            weekViewType
          );
        }

        if (isWeekView) {
          results.resources = getFormattedResources(
            data.resources,
            filteredIds
          );
        }

        return results;
      } catch (error) {
        fireTError('defaultError', { ns: 'common' });
        return calendarFetchDefault;
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      placeholderData: calendarFetchDefault,
    }
  );

  //Search Function
  const handleTermSearch = (data, offers, term) => {
    if (term !== oldTerm && oldTerm !== '') {
      setSearchMoreEventsInFuture('unset');
      setOldTerm(term);
      setRetrigger((prev) => !prev);
      return [];
    }

    setOldTerm(term);

    if (
      (data.length >= 1 && searchMoreEventsInFuture === 'unset') ||
      searchMoreEventsInFuture === 'allFetched'
    ) {
      setSearchMoreEventsInFuture('allFetched');
      return getFormattedEvents(data, offers, weekViewType);
    }

    if (data.length === 0 && searchMoreEventsInFuture === 'unset') {
      setSearchMoreEventsInFuture('fetchFuture');
      setRetrigger((prev) => !prev);
      return [];
    }

    if (searchMoreEventsInFuture === 'fetchFuture' && data.length >= 1) {
      setSearchMoreEventsInFuture('allFetched');
      setSelectedDateCalendar(
        DateTime.fromSeconds(data[0].start_time_epoch)
          .startOf('month')
          .toJSDate()
      );
      setRetrigger((prev) => !prev);
      return [];
    }

    if (data.length === 0 && searchMoreEventsInFuture !== 'unset') {
      setSearchMoreEventsInFuture('allFetched');
      callAlert();
      return [];
    }

    return [];
  };

  //Clear Search Function
  const clearSearch = () => {
    setTerm('');
    handleClose();
  };

  //Error Alert Search Function
  const callAlert = () => {
    addAlert({
      message: 'No results found.',
      severity: 'error',
      customComponent: (
        <TkAlert severity="info">
          <Trans i18nKey="calendar.search_no_results">
            <strong>No tasks were found.</strong> Please
            <u onClick={clearSearch}> clear the search to try again.</u>
          </Trans>
        </TkAlert>
      ),
    });
  };

  //Set Event/Offer for Proper Drawer
  const setDrawerEvent = (evt: number | string, isOffer = false) => {
    closeDrawers();

    if (isOffer) {
      const selectedOffer = getOfferById(evt as string, offers);

      if (!selectedOffer) {
        fireTError('error.offerNotFound', { ns: 'events' });
        return;
      }
      setEventId(0);
      openProjectOfferCalendarEvent(selectedOffer);
      return;
    }

    closeProjectOfferList();
    const evtId = evt === eventId ? 0 : (evt as number);
    setEventId(evtId);
  };

  //New Task Function
  const openNewTask = (date: string | null) => {
    resetMainEvent();
    closeDrawers();
    closeProjectOfferList();

    if (!date) return;

    const formattedDate = date.replace(/T.*/, '');
    const dateJS = getTimezoneDateFromISO(formattedDate, null).toJSDate();
    setEndDate(dateJS);
    setStartDate(dateJS);
    callDrawer({ main: { id: MainDrawersTypes.NEW_TASK } });
  };

  return {
    //User Props
    account,
    accountId,
    userInfo,
    signed,

    //Query Props
    setEnabled,
    retrigger,
    setRetrigger,

    //Alert
    callAlert,

    //Task Props
    openNewTask,

    //Date Props
    weekViewType,
    calendarView,
    setCalendarView,
    selectedDateCalendar,
    setSelectedDateCalendar,

    //Calendar Props
    isWeekView,
    isMonthView,
    isWeekViewTypeAssignee,
    isWeekViewTypeCustomer,
    dateModifierFlag,

    //Search Props
    term,
    oldTerm,
    setOldTerm,
    handleTermSearch,
    searchMoreEventsInFuture,
    setSearchMoreEventsInFuture,

    //Event-Offers Props
    isFetching,
    results,
    refetch,
    eventId,
    isLoadingEvt,
    setSelectedOffer,
    projectOffersCount,
    setEventId,
    refetchEvent,
    offerId,
    setOfferId,
    showRequestsButton,
    setOffersData,
    updateEvent,
    updateResourceEvent,
    setDrawerEvent,

    // Drawer Props
    drawers,
    isAnyDrawerOpen,
    setDrawerOpen,
  };
};
