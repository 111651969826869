import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Grid, FormGroup } from '@mui/material';

import {
  TkExtendedWrapper,
  TaskNotesField,
  TkTaskWrapperItem,
} from '../styles';
import { TkIcon, TkTypography } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { useDrawer } from '@hooks/index';
import { editEventNotes, getEventStatus } from '@recoilData/index';

const TkTaskNotes = (): JSX.Element => {
  const { t } = useTranslation();
  const { closeExtDrawer } = useDrawer();
  const [notes, setNotes] = useRecoilState(editEventNotes);
  const status = useRecoilValue(getEventStatus);

  return (
    <TkExtendedWrapper maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('guidance_notes', { ns: 'common' })}
        </TkTypography>
        <TkIcon
          onClick={closeExtDrawer}
          style={{ cursor: 'pointer' }}
          className="fa fa-chevron-right"
          color="default"
          marginLeft={10}
          verticalAlign="middle"
          fontSize="small"
        />
      </Grid>
      <TkTaskWrapperItem padding="5px 0">
        <FormGroup>
          <TaskNotesField
            variant="standard"
            value={notes}
            placeholder="Leave a note to your teammates"
            helperText={`${notes.length}/1500`}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            inputProps={{
              maxLength: 1500,
            }}
            multiline
            disabled={status === TaskStatusEnum.DONE}
          />
        </FormGroup>
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};

export default TkTaskNotes;
