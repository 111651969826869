import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import {
  AppLocationURLs,
  ICalendarViewEnum,
  TaskStatusEnum,
  WeekViewTypesEnum,
} from '@consts/index';
import {
  calendarGridViewSelector,
  calendarWeekViewSelector,
  isFilterSet,
  setInProgressStatus,
  setOnlyFilterAssignee,
  setToDoStatus,
  selectedDateCalendarData,
  setUnassignedStatus,
  setFilterByCustomerIdState,
} from '@recoilData/index';

export type TaskStatus = 'inProgress' | 'toDo' | 'notStarted';
export type TaskType = 'month' | 'week' | 'today';

export const useFilterCalendarTasks = (
  memberID: number,
  assigneeType: 'member' | 'customer' = 'customer'
) => {
  const history = useHistory();
  const setFilter = useSetRecoilState(isFilterSet);
  const setGridView = useSetRecoilState(calendarGridViewSelector);
  const setWeekViewType = useSetRecoilState(calendarWeekViewSelector);
  const setInProgressFilter = useSetRecoilState(setInProgressStatus);
  const setToDoFilter = useSetRecoilState(setToDoStatus);
  const setFilterOnlyMember = useSetRecoilState(setOnlyFilterAssignee);
  const setUnassignedFilter = useSetRecoilState(setUnassignedStatus);
  const setSelectedDateCalendar = useSetRecoilState<Date>(
    selectedDateCalendarData
  );

  const setFilterTaskByCustomerID = useSetRecoilState(
    setFilterByCustomerIdState(memberID)
  );

  const filterCalendarTaskByCustomer = (
    status: TaskStatus,
    type: TaskType = 'month',
    date?: string
  ) => {
    setFilter(true);
    setWeekViewType('customer');
    setFilterTaskByCustomerID(true);
    setGridView(
      type === 'month'
        ? ICalendarViewEnum.MONTH_VIEW
        : ICalendarViewEnum.WEEK_VIEW
    );
    if (status === TaskStatusEnum.TODO) setToDoFilter(true);
    // We have a rule that changes the status of ToDo to Unassigned,
    // so we need to check for both
    setUnassignedFilter(true);
    if (status === TaskStatusEnum.IN_PROGRESS) setInProgressFilter(true);
    if (date) setSelectedDateCalendar(DateTime.fromISO(date).toJSDate());
    history.push(AppLocationURLs.CALENDAR);
  };

  const filterTask = (
    taskStatus: TaskStatus,
    type: TaskType = 'month',
    date?: any
  ) => {
    setFilter(true);
    /* @ts-ignore */
    setFilterOnlyMember(memberID);
    if (type !== 'month') setWeekViewType(WeekViewTypesEnum.ASSIGNEE);
    setGridView(
      type === 'month'
        ? ICalendarViewEnum.MONTH_VIEW
        : ICalendarViewEnum.WEEK_VIEW
    );
    if (taskStatus === TaskStatusEnum.TODO) setToDoFilter(true);
    if (taskStatus === TaskStatusEnum.IN_PROGRESS) setInProgressFilter(true);
    if (date) setSelectedDateCalendar(DateTime.fromISO(date).toJSDate());
    history.push(AppLocationURLs.CALENDAR);
  };

  return {
    filterTask,
    filterCalendarTaskByCustomer,
  };
};
