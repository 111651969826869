import React from 'react';

import { Box, Grid } from '@mui/material';

import { TkChecklistSelectedListItem } from './index';
import { TkTaskWrapperItem, Selector } from './styles';
import { useTaskChecklists } from '../hooks/useTaskChecklists';
import { TkIcon, TkTypography } from '@components/index';
import { TaskStatusEnum } from "@consts/index";


interface TaskChecklistsProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const TkTaskChecklists = ({
  onMouseEnter,
  onMouseLeave,
}: TaskChecklistsProps): JSX.Element => {
  const {
    t,
    event,
    checklists,
    checklistsCount,
    changeExtendedDrawer,
    isChecklistDrawerOpen,
    handleSelectCheckList
  } = useTaskChecklists();

  return (
    <>
      <TkTaskWrapperItem
        bgcolor={isChecklistDrawerOpen ? 'selected' : ''}
        padding="10px 1rem"
        marginTop="5px"
      >
        <TkTypography fontFamily="Lato" fontSize={16}>
          <Grid
            container
            justifyContent="space-between"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Box width="auto">
              <Box
                fontSize={16}
                display="inline"
                marginRight="5px"
                alignItems="center"
              >
                <TkIcon
                  className="fa fa-tasks"
                  iconSize={14}
                  color={
                    isChecklistDrawerOpen ? '#ffffff' : 'rgba(1,31,65,0.8)'
                  }
                  verticalAlign="middle"
                />
              </Box>
              {t('checklists', { ns: 'common' })}
            </Box>
            {checklistsCount === 0 && event.status !== TaskStatusEnum.DONE && (
              <Box>
                <TkTypography
                  fontSize={16}
                  fontWeight="bold"
                  fontFamily="Lato"
                  userselect="none"
                  onClick={changeExtendedDrawer}
                  color={isChecklistDrawerOpen ? '#ffffff' : 'primary'}
                  textAlign="right"
                  style={{ cursor: 'pointer' }}
                >
                  + {t('checklists', { ns: 'common' })}
                </TkTypography>
              </Box>
            )}
          </Grid>
        </TkTypography>
      </TkTaskWrapperItem>
      {checklistsCount >= 1 && (
        <TkTaskWrapperItem padding="0 10px 10px 10px">
          {checklists.map((checklist) => (
            <TkChecklistSelectedListItem
              key={checklist.id}
              status={event.status}
              checklist={checklist}
              eventChecklists={event.checklists}
              handleSelectCheckList={() => handleSelectCheckList(checklist.id)}

            />
          ))}
          {event.status !== TaskStatusEnum.DONE && (
            <Selector
              onClick={changeExtendedDrawer}
              style={{ marginLeft: '5px' }}
            >
              <TkTypography
                fontWeight="bold"
                fontFamily="Lato"
                fontSize={16}
                color="primary"
                userselect="none"
              >
                + {t('checklists', { ns: 'common' })}
              </TkTypography>
            </Selector>
          )}
        </TkTaskWrapperItem>
      )}
    </>
  );
};

export default TkTaskChecklists;
