import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from "recoil";

import { EventTypeEnum, TaskStatusEnum } from "@consts/index";
import { useDrawer } from '@hooks/index';
import {
  editEventSelectedChecklist,
  event as eventRecoil,
  getEditEventChecklistsCount,
  getSelectedEditEventChecklists
} from "@recoilData/index";

export const useTaskChecklists = () => {
  const { t } = useTranslation();
  const {
    callDrawer,
    currentDrawer: { extended },
    types: { ExtendedDrawersTypes },
  } = useDrawer();

  const isChecklistDrawerOpen =
    extended && extended.id === ExtendedDrawersTypes.TASK_CHECKLIST;

  // Recoil Values
  const event = useRecoilValue(eventRecoil);
  const checklists = useRecoilValue(getSelectedEditEventChecklists);
  const checklistsCount = useRecoilValue(getEditEventChecklistsCount);
  const setSelectedChecklist= useSetRecoilState(
    editEventSelectedChecklist
  );
  const changeExtendedDrawer = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.TASK_CHECKLIST,
        props: {
          eventType: EventTypeEnum.EDIT,
        },
      },
    });


  const handleSelectCheckList = (checkListID: number) => {

    setSelectedChecklist(event.checklists.find(
      (evtChecklist) => evtChecklist.id === checkListID
    ));

    if (event.status === TaskStatusEnum.DONE || event.status === TaskStatusEnum.IN_PROGRESS)
      callDrawer({
        extended: {
          id: ExtendedDrawersTypes.EDIT_TASK_CHECKLIST_LIST,
          props: {
            eventType: EventTypeEnum.EDIT,
          },
        },
      });
  }

  return {
    t,
    event,
    checklists,
    checklistsCount,
    changeExtendedDrawer,
    isChecklistDrawerOpen,
    handleSelectCheckList
  };
};
